var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.questionDetail)?_c('div',{staticClass:"xw-question-detail"},[_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.moment(_vm.questionDetail.advisoryInfo.questionTime).calendar())+" ")]),_c('div',{staticClass:"question"},[_c('div',{staticClass:"title"},[_vm._v("第一次提问：")]),_c('div',{staticClass:"content"},[_vm._v(_vm._s(_vm.questionDetail.advisoryInfo.content))])]),_vm._l((_vm.questionDetail.advisoryReplyList),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.moment(item.createTime).calendar())+" ")]),_c('div',{class:{ answer: item.isReply, question: !item.isReply }},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.isReply ? "老师回复：" : "追问："))]),_c('div',{staticClass:"content"},[_vm._v(_vm._s(item.content))])])])}),(_vm.questionDetail.advisoryInfo.questionCount === 3)?_c('div',{staticClass:"tips"},[_vm._v(" 3次追问次数已用完 ")]):_vm._e(),(
      !_vm.questionDetail.advisoryInfo.isReplied ||
      !_vm.questionDetail.advisoryReplyList[
        _vm.questionDetail.advisoryReplyList.length - 1
      ].isReply
    )?_c('div',{staticClass:"tips"},[_vm._v(" 请耐心等待老师回复 ")]):_vm._e(),(
      _vm.questionDetail.advisoryInfo.isReplied &&
      _vm.questionDetail.advisoryInfo.questionCount < 3 &&
      _vm.questionDetail.advisoryReplyList[
        _vm.questionDetail.advisoryReplyList.length - 1
      ].isReply
    )?_c('div',{staticClass:"cell"},[_c('div',{staticClass:"cell__bd"},[_c('div',{staticClass:"textarea"},[_c('el-input',{attrs:{"type":"textarea","rows":8,"placeholder":"请详细描述你的问题","resize":"none","maxlength":"500"},model:{value:(_vm.question),callback:function ($$v) {_vm.question=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"question"}}),_c('div',{staticClass:"textarea__tip"},[_c('div',{staticClass:"textarea__counter"},[_c('span',{},[_vm._v(_vm._s(_vm.question.length)+"/500")])])])],1)]),_c('div',{staticClass:"cell__ft"})]):_vm._e(),(
      _vm.questionDetail.advisoryInfo.isReplied &&
      _vm.questionDetail.advisoryInfo.questionCount < 3 &&
      _vm.questionDetail.advisoryReplyList[
        _vm.questionDetail.advisoryReplyList.length - 1
      ].isReply
    )?_c('div',{staticClass:"question-btn",on:{"click":_vm.zhuiwen}},[_vm._v(" 追问（共3次追问机会，剩余"+_vm._s(3 - _vm.questionDetail.advisoryInfo.questionCount)+"次） ")]):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }