<template>
  <div class="xw-question-detail" v-if="questionDetail">
    <div class="time">
      {{ moment(questionDetail.advisoryInfo.questionTime).calendar() }}
    </div>
    <div class="question">
      <div class="title">第一次提问：</div>
      <div class="content">{{ questionDetail.advisoryInfo.content }}</div>
    </div>
    <div v-for="(item, index) in questionDetail.advisoryReplyList" :key="index">
      <div class="time">
        {{ moment(item.createTime).calendar() }}
      </div>
      <div :class="{ answer: item.isReply, question: !item.isReply }">
        <div class="title">{{ item.isReply ? "老师回复：" : "追问：" }}</div>
        <div class="content">{{ item.content }}</div>
      </div>
    </div>
    <div class="tips" v-if="questionDetail.advisoryInfo.questionCount === 3">
      3次追问次数已用完
    </div>
    <div
      class="tips"
      v-if="
        !questionDetail.advisoryInfo.isReplied ||
        !questionDetail.advisoryReplyList[
          questionDetail.advisoryReplyList.length - 1
        ].isReply
      "
    >
      请耐心等待老师回复
    </div>
    <div
      class="cell"
      v-if="
        questionDetail.advisoryInfo.isReplied &&
        questionDetail.advisoryInfo.questionCount < 3 &&
        questionDetail.advisoryReplyList[
          questionDetail.advisoryReplyList.length - 1
        ].isReply
      "
    >
      <div class="cell__bd">
        <div class="textarea">
          <el-input
            type="textarea"
            :rows="8"
            placeholder="请详细描述你的问题"
            v-model.trim="question"
            resize="none"
            maxlength="500"
          >
          </el-input>
          <div class="textarea__tip">
            <div class="textarea__counter">
              <span class="">{{ question.length }}/500</span>
            </div>
          </div>
        </div>
      </div>
      <div class="cell__ft"></div>
    </div>
    <div
      class="question-btn"
      v-if="
        questionDetail.advisoryInfo.isReplied &&
        questionDetail.advisoryInfo.questionCount < 3 &&
        questionDetail.advisoryReplyList[
          questionDetail.advisoryReplyList.length - 1
        ].isReply
      "
      @click="zhuiwen"
    >
      追问（共3次追问机会，剩余{{
        3 - questionDetail.advisoryInfo.questionCount
      }}次）
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "xw-question-detail",
  data() {
    return {
      moment,
      id: null,
      questionDetail: null,
      question: "",
    };
  },
  created() {
    document.title = "小文老师";
    moment.locale("zh-cn");
    this.id = this.$router.history.current.query.id;
    this.getQuestionDetail();
  },
  methods: {
    getQuestionDetail() {
      this.axios
        .post("/api/user/advisory/info", {
          advisoryId: this.id,
        })
        .then((res) => {
          console.log(res);
          this.questionDetail = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    zhuiwen() {
      if (!this.question) {
        this.$message({
          type: "warning",
          message: "问题不能为空",
        });
        return;
      }
      this.axios
        .post("/api/user/advisory/questioning", {
          advisoryId: this.id,
          content: this.question,
        })
        .then(() => {
          this.getQuestionDetail();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.xw-question-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f2f2f2;
  padding-bottom: 30px;
  .time {
    text-align: center;
    color: #999;
    padding: 15px 0 5px;
  }
  .question {
    background-color: #e6edf5;
    padding: 20px 15px;
    width: 768px;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .answer {
    box-sizing: border-box;
    background-color: #fff;
    width: 768px;
    border-radius: 4px;
    padding: 20px 15px;
  }
  .title {
    color: #333;
    font-weight: 500;
    padding-bottom: 20px;
  }
  .content {
    color: #999;
    white-space: normal !important;
    font-family: PingFang SC, Helvetica Neue, Helvetica, Hiragino Sans GB,
      Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, Arial, sans-serif;
  }
  .cell {
    position: relative;
    width: 768px;
    margin-top: 20px;
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      border-top: 1px solid #ebebeb;
      transform: scaleY(0.5);
      transform-origin: 0 100%;
      content: "";
    }
    .cell__bd {
      flex: 1;
      overflow: hidden;
      .textarea {
        position: relative;
        width: 100%;
        .textarea__tip {
          position: relative;
          margin-top: 15px;
          line-height: 13px;
          text-align: right;
          .textarea__counter {
            display: inline-block;
            color: #ccc;
          }
        }
      }
    }
  }
  .question-btn {
    background-color: #00c792;
    color: #ffffff;
    width: 768px;
    height: 46px;
    border-radius: 23px;
    font-size: 15px;
    text-align: center;
    line-height: 46px;
    cursor: pointer;
    margin: 30px 0;
  }
  .tips {
    color: #999;
    text-align: center;
    line-height: 50px;
  }
}
</style>